<template>
  <div>
    <v-row justify="center" no-gutters>
      <h1 v-bind:class="mobile ? 'mainTitleMobile' : 'mainTitle'">{{ $t('mainTitle') }}</h1>
    </v-row>
    <v-icon
        v-bind:class="mobile ? 'iconBackMobile' : 'iconBack'"
        v-bind:size="mobile ? 40 : 50"
        color="#1579A7"
        @click="$router.push({name: 'PhysicalActivities'}).then(() => {$vuetify.goTo(0)})"
    >
      mdi-arrow-left
    </v-icon>
    <v-row justify="center" v-bind:class="mobile ? 'sectionMobile' : 'section'" no-gutters>
      <v-col v-bind:cols="mobile ? 10 : 6" class="leftSection">
        <p v-bind:class="mobile ? 'textSection1Mobile' : 'textSection1'" v-html="$t('textSection1')"></p>
      </v-col>
      <v-col v-bind:cols="mobile ? 10 : 6" v-bind:class="mobile ? 'rightSectionMobile' : 'rightSection'">
        <p v-bind:class="mobile ? 'textSection1Mobile' : 'textSection1'" style="color: #57939E;" v-html="$t('textSection2')"></p>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" v-bind:class="mobile ? 'lastSectionMobile' : 'lastSection'" no-gutters>
      <v-col v-bind:cols="mobile ? 10 : 3">
        <v-card
            v-bind:class="mobile ? 'cardMobile' : 'mx-auto'"
            v-bind:width="!mobile && (width < 1500) ? 275 : ''"
            v-bind:max-width="340"
            style="text-align: center;"
        >
          <v-card-title>
            <div v-bind:class="mobile ? 'cardTitleMobile' : 'cardTitle'">{{ $t('cardTitle1') }}</div>
            <br>
            <br>
          </v-card-title>
            <v-img
                v-if="!mobile"
                :src="require('@/assets/conferenceFormation/conference9.jpg')"
                contain
                width="100%"
            />
          <v-card-text>
            <div v-bind:class="mobile ? 'cardSubtitleMobile' : 'cardSubtitle'">{{ $t('cardSubtitle') }}</div>
            <v-row justify="center" align="center" no-gutters class="cardContent">
              <v-col cols="6">
                <v-img
                    :src="require('@/assets/activities/heart.svg')"
                    contain
                    height="40"
                />
                <div class="textPart" v-html="$t('textPart1')"></div>
              </v-col>
              <v-col cols="6" align-self="center">
                <v-img
                    :src="require('@/assets/activities/2.svg')"
                    contain
                    height="20"
                />
              </v-col>
              <v-col cols="6">
                <v-img
                    :src="require('@/assets/activities/alter.svg')"
                    contain
                    height="40"
                />
                <div class="textPart" v-html="$t('textPart2')"></div>
              </v-col>
              <v-col cols="6" align-self="center">
                <v-img
                    :src="require('@/assets/activities/4.svg')"
                    contain
                    height="20"
                />
              </v-col>
              <v-col cols="6">
                <v-img
                    :src="require('@/assets/activities/run.svg')"
                    contain
                    height="40"
                />
                <div class="textPart" v-html="$t('textPart3')"></div>
              </v-col>
              <v-col cols="6" align-self="center">
                <v-img
                    :src="require('@/assets/activities/1.svg')"
                    contain
                    height="20"
                />
              </v-col>
              <v-col cols="6">
                <v-img
                    :src="require('@/assets/activities/sport.svg')"
                    contain
                    height="40"
                />
                <div class="textPart" v-html="$t('textPart4')"></div>
              </v-col>
              <v-col cols="6" align-self="center">
                <v-img
                    :src="require('@/assets/activities/4.svg')"
                    contain
                    height="20"
                />
              </v-col>
              <v-col cols="6">
                <v-img
                    :src="require('@/assets/activities/graph.svg')"
                    contain
                    height="40"
                />
                <div class="textPart" v-html="$t('textPart5')"></div>
              </v-col>
              <v-col cols="6" align-self="center">
                <v-img
                    :src="require('@/assets/activities/1.svg')"
                    contain
                    height="20"
                />
              </v-col>
            </v-row>
            <v-img
                class="separatorCard"
                :src="require('@/assets/picto/separator14.svg')"
                contain
                v-bind:height="mobile ? 20 : 25"
            />
            <div v-bind:class="mobile ? 'cardTextMobile' : 'cardText'">{{ $t('cardText1') }}</div>
            <v-row justify="center" align="center" no-gutters class="locCard">
              <v-col cols="2">
                <v-img
                    :src="require('@/assets/activities/loc.svg')"
                    contain
                    v-bind:height="mobile ? 30 : 40"
                />
              </v-col>
              <v-col cols="10">
                <div>{{ $t('cardText2') }}</div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-bind:cols="mobile ? 10 : 3">
        <v-card
            v-bind:class="mobile ? 'cardMobile' : 'mx-auto'"
            v-bind:width="!mobile && (width < 1500) ? 275 : ''"
            v-bind:max-width="340"
            style="text-align: center;"
        >
          <v-card-title>
            <div v-bind:class="mobile ? 'cardTitleMobile' : 'cardTitle'">{{ $t('cardTitle2') }}</div>
            <br>
            <br>
          </v-card-title>
          <v-img
              v-if="!mobile"
              :src="require('@/assets/conferenceFormation/conference10.jpg')"
              contain
              width="100%"
          />
          <v-card-text>
            <div v-bind:class="mobile ? 'cardSubtitleMobile' : 'cardSubtitle'">{{ $t('cardSubtitle') }}</div>
            <v-row justify="center" align="center" no-gutters class="cardContent">
              <v-col cols="6">
                <v-img
                    :src="require('@/assets/activities/heart.svg')"
                    contain
                    height="40"
                />
                <div class="textPart" v-html="$t('textPart1')"></div>
              </v-col>
              <v-col cols="6" align-self="center">
                <v-img
                    :src="require('@/assets/activities/4.svg')"
                    contain
                    height="20"
                />
              </v-col>
              <v-col cols="6">
                <v-img
                    :src="require('@/assets/activities/alter.svg')"
                    contain
                    height="40"
                />
                <div class="textPart" v-html="$t('textPart2')"></div>
              </v-col>
              <v-col cols="6" align-self="center">
                <v-img
                    :src="require('@/assets/activities/4.svg')"
                    contain
                    height="20"
                />
              </v-col>
              <v-col cols="6">
                <v-img
                    :src="require('@/assets/activities/run.svg')"
                    contain
                    height="40"
                />
                <div class="textPart" v-html="$t('textPart3')"></div>
              </v-col>
              <v-col cols="6" align-self="center">
                <v-img
                    :src="require('@/assets/activities/4.svg')"
                    contain
                    height="20"
                />
              </v-col>
              <v-col cols="6">
                <v-img
                    :src="require('@/assets/activities/sport.svg')"
                    contain
                    height="40"
                />
                <div class="textPart" v-html="$t('textPart4')"></div>
              </v-col>
              <v-col cols="6" align-self="center">
                <v-img
                    :src="require('@/assets/activities/2.svg')"
                    contain
                    height="20"
                />
              </v-col>
              <v-col cols="6">
                <v-img
                    :src="require('@/assets/activities/graph.svg')"
                    contain
                    height="40"
                />
                <div class="textPart" v-html="$t('textPart5')"></div>
              </v-col>
              <v-col cols="6" align-self="center">
                <v-img
                    :src="require('@/assets/activities/2.svg')"
                    contain
                    height="20"
                />
              </v-col>
            </v-row>
            <v-img
                class="separatorCard"
                :src="require('@/assets/picto/separator14.svg')"
                contain
                v-bind:height="mobile ? 20 : 25"
            />
            <div v-bind:class="mobile ? 'cardTextMobile' : 'cardText'">{{ $t('cardText1') }}</div>
            <v-row justify="center" align="center" no-gutters class="locCard">
              <v-col cols="2">
                <v-img
                    :src="require('@/assets/activities/loc.svg')"
                    contain
                    v-bind:height="mobile ? 30 : 40"
                />
              </v-col>
              <v-col cols="10">
                <div>{{ $t('cardText2') }}</div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-bind:cols="mobile ? 10 : 3">
        <v-card
            v-bind:class="mobile ? 'cardMobile' : 'mx-auto'"
            v-bind:width="!mobile && (width < 1500) ? 275 : ''"
            v-bind:max-width="340"
            style="text-align: center;"
        >
          <v-card-title>
            <div v-bind:class="mobile ? 'cardTitleMobile' : 'cardTitle'">{{ $t('cardTitle3') }}</div>
            <br>
            <br>
          </v-card-title>
          <v-img
              v-if="!mobile"
              :src="require('@/assets/conferenceFormation/conference11.jpg')"
              contain
              width="100%"
          />
          <v-card-text>
            <div v-bind:class="mobile ? 'cardSubtitleMobile' : 'cardSubtitle'">{{ $t('cardSubtitle') }}</div>
            <v-row justify="center" align="center" no-gutters class="cardContent">
              <v-col cols="6">
                <v-img
                    :src="require('@/assets/activities/heart.svg')"
                    contain
                    height="40"
                />
                <div class="textPart" v-html="$t('textPart1')"></div>
              </v-col>
              <v-col cols="6" align-self="center">
                <v-img
                    :src="require('@/assets/activities/2.svg')"
                    contain
                    height="20"
                />
                <v-img
                    style="margin-top: 2px"
                    :src="require('@/assets/activities/4.svg')"
                    contain
                    height="20"
                />
              </v-col>
              <v-col cols="6">
                <v-img
                    :src="require('@/assets/activities/alter.svg')"
                    contain
                    height="40"
                />
                <div class="textPart" v-html="$t('textPart2')"></div>
              </v-col>
              <v-col cols="6" align-self="center">
                <v-img
                    :src="require('@/assets/activities/3.svg')"
                    contain
                    height="20"
                />
              </v-col>
              <v-col cols="6">
                <v-img
                    :src="require('@/assets/activities/run.svg')"
                    contain
                    height="40"
                />
                <div class="textPart" v-html="$t('textPart3')"></div>
              </v-col>
              <v-col cols="6" align-self="center">
                <v-img
                    :src="require('@/assets/activities/5.svg')"
                    contain
                    height="20"
                />
              </v-col>
              <v-col cols="6">
                <v-img
                    :src="require('@/assets/activities/sport.svg')"
                    contain
                    height="40"
                />
                <div class="textPart" v-html="$t('textPart4')"></div>
              </v-col>
              <v-col cols="6" align-self="center">
                <v-img
                    :src="require('@/assets/activities/2.svg')"
                    contain
                    height="20"
                />
              </v-col>
              <v-col cols="6">
                <v-img
                    :src="require('@/assets/activities/graph.svg')"
                    contain
                    height="40"
                />
                <div class="textPart" v-html="$t('textPart5')"></div>
              </v-col>
              <v-col cols="6" align-self="center">
                <v-img
                    :src="require('@/assets/activities/1.svg')"
                    contain
                    height="20"
                />
              </v-col>
            </v-row>
            <v-img
                class="separatorCard"
                :src="require('@/assets/picto/separator14.svg')"
                contain
                v-bind:height="mobile ? 20 : 25"
            />
            <div v-bind:class="mobile ? 'cardTextMobile' : 'cardText'">{{ $t('cardText1') }}</div>
            <v-row justify="center" align="center" no-gutters class="locCard">
              <v-col cols="2">
                <v-img
                    :src="require('@/assets/activities/loc.svg')"
                    contain
                    v-bind:height="mobile ? 30 : 40"
                />
              </v-col>
              <v-col cols="10">
                <div>{{ $t('cardText2') }}</div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-bind:cols="mobile ? 10 : 3">
        <v-card
            v-bind:class="mobile ? 'cardMobile' : 'mx-auto'"
            v-bind:width="!mobile && (width < 1500) ? 275 : ''"
            v-bind:max-width="340"
            style="text-align: center;"
        >
          <v-card-title>
            <div v-bind:class="mobile ? 'cardTitleMobile' : 'cardTitle'">{{ $t('cardTitle4') }}</div>
          </v-card-title>
          <v-img
              v-if="!mobile"
              :src="require('@/assets/conferenceFormation/conference12.jpg')"
              contain
              width="100%"
          />
          <v-card-text>
            <div v-bind:class="mobile ? 'cardSubtitleMobile' : 'cardSubtitle'">{{ $t('cardSubtitle') }}</div>
            <v-row justify="center" align="center" no-gutters class="cardContent">
              <v-col cols="6">
                <v-img
                    :src="require('@/assets/activities/heart.svg')"
                    contain
                    height="40"
                />
                <div class="textPart" v-html="$t('textPart1')"></div>
              </v-col>
              <v-col cols="6" align-self="center">
                <v-img
                    :src="require('@/assets/activities/0.svg')"
                    contain
                    height="20"
                />
              </v-col>
              <v-col cols="6">
                <v-img
                    :src="require('@/assets/activities/alter.svg')"
                    contain
                    height="40"
                />
                <div class="textPart" v-html="$t('textPart2')"></div>
              </v-col>
              <v-col cols="6" align-self="center">
                <v-img
                    :src="require('@/assets/activities/0.svg')"
                    contain
                    height="20"
                />
              </v-col>
              <v-col cols="6">
                <v-img
                    :src="require('@/assets/activities/run.svg')"
                    contain
                    height="40"
                />
                <div class="textPart" v-html="$t('textPart3')"></div>
              </v-col>
              <v-col cols="6" align-self="center">
                <v-img
                    :src="require('@/assets/activities/0.svg')"
                    contain
                    height="20"
                />
              </v-col>
              <v-col cols="6">
                <v-img
                    :src="require('@/assets/activities/sport.svg')"
                    contain
                    height="40"
                />
                <div class="textPart" v-html="$t('textPart4')"></div>
              </v-col>
              <v-col cols="6" align-self="center">
                <v-img
                    :src="require('@/assets/activities/0.svg')"
                    contain
                    height="20"
                />
              </v-col>
              <v-col cols="6">
                <v-img
                    :src="require('@/assets/activities/graph.svg')"
                    contain
                    height="40"
                />
                <div class="textPart" v-html="$t('textPart5')"></div>
              </v-col>
              <v-col cols="6" align-self="center">
                <v-img
                    :src="require('@/assets/activities/0.svg')"
                    contain
                    height="20"
                />
              </v-col>
            </v-row>
            <v-img
                class="separatorCard"
                :src="require('@/assets/picto/separator14.svg')"
                contain
                v-bind:height="mobile ? 20 : 25"
            />
            <div v-bind:class="mobile ? 'cardTextMobile' : 'cardText'">{{ $t('cardText1') }}</div>
            <v-row justify="center" align="center" no-gutters class="locCard">
              <v-col cols="2">
                <v-img
                    :src="require('@/assets/activities/loc.svg')"
                    contain
                    v-bind:height="mobile ? 30 : 40"
                />
              </v-col>
              <v-col cols="10">
                <div>{{ $t('cardText2') }}</div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'SportsTherapyComp',
    props: ['mobile'],
    data: () => ({
      width: screen.width
    }),
  }
</script>

<i18n>
{
  "en": {
    "mainTitle": "Sports therapy",
    "cardTitle1": "The balance gym",
    "cardTitle2": "The active gym",
    "cardTitle3": "Nordic walking",
    "cardTitle4": "Individual support",
    "cardSubtitle": "Characteristics :",
    "textPart1": "Intensity",
    "textPart2": "Muscular work",
    "textPart3": "Heart work",
    "textPart4": "Balance work",
    "textPart5": "Required level",
    "cardText1": "Thursdays from 9:30 a.m. to 10:00 a.m.",
    "cardText2": "Salle du portail",
    "textSection1": "The positive effects on health of daily adapted physical activity are no longer to be proven. All the more so for people who are strongly deconditioned because of a pathology or an accident. <br><br> In this context, we have adapted some of our activities in order to be able to accommodate vulnerable people in order to use physical activity as a non-drug therapy.",
    "textSection2": "<b>Assessment and orientation </b> <br><br> Vitaé Sport Santé offers you a complete biopsychosocial assessment followed by a motivational interview to determine which activity would suit you best according to your state of form and of your goals."
  },
  "fr": {
    "mainTitle": "Le sport thérapie",
    "cardTitle1": "La gym équilibre",
    "cardTitle2": "La gym active",
    "cardTitle3": "La marche nordique",
    "cardTitle4": "La prise en charge individuelle",
    "cardSubtitle": "Caractéristiques :",
    "textPart1": "Intensité",
    "textPart2": "Travail musculaire",
    "textPart3": "Travail cardio",
    "textPart4": "Travail d'équilibre",
    "textPart5": "Niveau requis",
    "cardText1": "Les jeudis de 09h30 à 10h00",
    "cardText2": "Salle du portail",
    "textSection1": "Les effets positifs sur la santé d’une activité physique adaptée quotidienne n’est aujourd'hui plus à prouver. D’autant plus pour des personnes fortement déconditionné à cause d’une pathologie ou d’un accident. <br><br> Dans ce cadre, nous avons adaptée certaines de nos activités afin de pouvoir accueillir des personnes fragilisées afin d’utiliser l’activité physique comme thérapie non médicamenteuse.",
    "textSection2": "<b>Evaluation et orientation </b> <br><br> Vitaé Sport Santé vous propose une évaluation biopsychosociale complète suivi d’un entretien motivationnel afin de déterminer qu’elle activité vous conviendrait le mieux en fonction de votre état de forme et de vos objectifs."
  }
}
</i18n>

<style scoped>
.leftSection {
  padding-left: 200px;
  text-align: left;
}
@media screen and (max-width: 1500px) {
  .section {
    padding-left: 200px;
    padding-right: 200px;
  }
  .secondSection {
    padding-left: 200px;
    padding-right: 200px;
    position: relative;
    color: rgba(50, 50, 50, 0.75);
    box-shadow: 0 7px 3px -3px rgba(87, 147, 158, 0.20);
  }
  .chevronDown {
    text-align: center;
  }
  .lastSection {
    padding: 20px 100px 60px;
  }
}
@media screen and (min-width: 1500px) {
  .section {
    padding-left: 200px;
    padding-right: 200px;
    padding-bottom: 50px;

  }
  .secondSection {
    padding-left: 200px;
    padding-right: 200px;
    position: relative;
    color: rgba(50, 50, 50, 0.75);
    box-shadow: 0 7px 3px -3px rgba(87, 147, 158, 0.20);
  }
  .chevronDown {
    text-align: center;
  }
  .lastSection {
    padding-bottom: 60px;
    padding-left: 200px;
    padding-right: 200px;
  }
}

.lastSectionMobile {
  padding-bottom: 60px;
}

.cardContent {
  padding-top: 40px;
}

.cardMobile {
  margin-bottom: 20px;
}

.secondSectionMobile {
  padding-top: 50px;
  padding-bottom: 40px;
  position: relative;
  color: rgba(50, 50, 50, 0.75);
  box-shadow: 0 7px 3px -3px rgba(87, 147, 158, 0.20);
}

.locCard {
  text-align: left;
  padding-top: 20px;
}

.cardText {
  font-weight: 600;
}

.cardSubtitle {
  color: #1579A7;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
}

.cardSubtitleMobile {
  color: #1579A7;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
}

.separatorCard {
  margin-top: 20px;
  margin-bottom: 20px;
}

.separatorLast {
  margin-top: 40px;
  margin-bottom: 80px;
}

.separatorLastMobile {
  margin-top: 20px;
  margin-bottom: 40px;
}

.cardTitle {
  font-size: 22px;
  font-weight: 800;
  font-family: Comfortaa, sans-serif;
  color: #1579A7;
  word-break: normal;
}

.cardTitleMobile {
  font-size: 18px;
  font-weight: 800;
  font-family: Comfortaa, sans-serif;
  color: #1579A7;
}

.textPart {
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: bold;
  font-family: Comfortaa, sans-serif;
  text-align: center;
  font-size: 16px;
  color: #1579A7;
}

.textPartMobile {

}

.secondTitle {
  padding-top: 60px;
  padding-bottom: 60px;
  font-size: 30px;
  font-weight: 800;
  font-family: Comfortaa, sans-serif;
  color: #1579A7;
}

.iconBack {
  position: absolute;
  top: 30px;
  left: 80px;
}


.iconBackMobile {
  position: absolute;
  top: 55px;
  left: 30px;
}

.rightSection {
  padding-right: 200px;
}

.rightSectionMobile {
  margin-bottom: 40px;
}

.textSection1 {
  padding-right: 100px;
  color: #808080;
}
.textSection1Mobile {
  font-size: 14px ;
  color: #808080;
}
</style>
